import { ArrowCircleLeftIcon } from "@heroicons/react/outline";
import { navigate } from "gatsby";
import React from "react";
import Layout from "../layouts/layout";
import Moment from "moment";


const NewsDetails = ({ pageContext }: any) => {

  const urlImage = pageContext.image?.imageFile?.publicURL;
  const content = pageContext.content;
  const title = pageContext.title;
  const author = pageContext.author;
  const date_published = pageContext.date_published;
  const categories_id = pageContext.categories[0]?.categories_id?.id
  
  Moment.locale("fr");

  const gotToPreviousPage = () => {
    navigate(-1);
  }

  return (
    <Layout>
      <div className="tw-flex tw-justify-center">
        <div style={{width:"90%"}}>
          <div className="tw-w-full" style={{marginTop: 25}}>
            <div className="tw-space-y-6 lg:tw-col-start-1 lg:tw-col-span-2">
              <div className="tw-bg-white tw-shadow tw-rounded-xl">
                <div className="tw-px-4 tw-py-5 sm:tw-px-6">
                  {
                    (categories_id == '1') ?
                    <button
                      aria-label="Page d'actualités"
                      className="tw-flex flex-inline-col hover:tw-text-gray-500 tw-pb-2"
                      onClick={gotToPreviousPage}
                    >
                      <ArrowCircleLeftIcon
                        className="tw-h-6 tw-w-6 tw-mr-2"
                        aria-hidden="true"
                      />
                      <span className="tw-underline">Retour</span>
                    </button>
                    :
                    <button
                      aria-label="Page d'actualités"
                      className="tw-flex flex-inline-col  hover:tw-text-gray-500 tw-pb-2"
                      onClick={gotToPreviousPage}
                    >
                      <ArrowCircleLeftIcon
                        className="tw-h-6 tw-w-6 tw-mr-2"
                        aria-hidden="true"
                      />
                     <span className="tw-underline">Retour</span>
                    </button>
                  }
                  <h3
                    // data-typesense-field="title"
                    className="tw-text-2xl"
                  >
                    {title}
                  </h3>
                  <p className=" tw-text-lg tw-leading-6 text-project-primary tw-font-semibold tw-tracking-wide tw-uppercase tw-mt-2">
                    Soumis par {author} le{" "}
                    {Moment(date_published).format("D MMM YYYY")}
                  </p>
                  {/* <Tag title={post.keywords} />{" "} */}
                </div>
              </div>
            </div>
          </div>
          <div className="tw-flex tw-mt-8 tw-gap-8 tw-flex-wrap md:tw-flex-nowrap tw-mb-8">
            <div className="tw-w-full md:tw-w-3/4">
              <div className="tw-bg-white tw-shadow tw-rounded-xl">
                <div
                  className="tw-px-4 tw-py-5 sm:tw-px-6"
                  style={{
                    wordWrap: "break-word",
                    whiteSpace: "pre-wrap",
                    wordBreak: "break-word",
                  }}
                >
                  <div className="tw-flex tw-justify-center">
                    <div className="tw-mb-5">
                      <img src={urlImage} alt="" className="tw-z-10" />
                    </div>
                  </div>
                  <div
                    data-typesense-field="description"
                    className="tw-my-10 tw-text-justify"
                    dangerouslySetInnerHTML={{ __html: content }}
                  ></div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </Layout>
  );
};

export default NewsDetails;
